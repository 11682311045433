* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  font-family: 'Geist Mono', monospace;
  position: relative;
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url('/images/background-blur.jpg');
  background-size: cover;
  background-position: left center;
  z-index: -1;
}

.logo {
  position: absolute;
  top: 2rem;
  left: 2rem;
  max-width: 200px;
}

.logo img {
  width: 100%;
  height: auto;
}

.message {
  position: absolute;
  bottom: 2rem;
  left: 0;
  width: 100%;
  text-align: center;
  color: white;
  padding: 1rem;
  font-size: 1.2rem;
}
